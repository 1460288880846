<template>
  <div :class="$style.ClaimCaptcha">
    <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="gcaptchaKey"></div>
  </div>
</template>

<script setup lang="ts">
const { gcaptchaKey } = useRuntimeConfig().public;
const recaptchaSrc = "https://www.google.com/recaptcha/api.js?render=explicit";

const widgetID = ref(null);

const colorMode = useColorMode();

function loadRecaptcha(callback: () => void) {
  if (window && !window.grecaptcha) {
    const recaptchaScript = document.createElement("script");
    document.head.appendChild(recaptchaScript);
    recaptchaScript.onload = () => {
      window.grecaptcha.ready(() => {
        callback();
      });
    };
    recaptchaScript.setAttribute("src", recaptchaSrc);
  } else {
    callback();
  }
}

const emit = defineEmits(["input"]);

function render() {
  if (!window.grecaptcha) {
    return;
  }
  widgetID.value = window.grecaptcha.render("g-recaptcha", {
    sitekey: gcaptchaKey,
    theme: colorMode.value,
    hl: "ru",
    callback: (token: string) => {
      emit("input", token);
    },
  });
}

onMounted(() => {
  loadRecaptcha(render);
});
</script>

<style lang="scss" module>
.ClaimCaptcha {
  //
}
</style>
